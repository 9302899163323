import { Dispatch, SetStateAction, useState } from "react";
import WizardDescriptionForm from "./WizardDescriptionForm";

import {
  createPlanCompletion,
  createTitleCompletion,
} from "../../../../completion/completionAPI";
import { fromHTML, toHTML } from "../../../../../../../shared/utils/html";
import { SurveyData } from "../../../survey.types";
import { useSurveySetters } from "../../hooks/useSurvey";
import WizardPlanForm from "./WizardPlanForm";
import { motion } from "framer-motion";
import {
  ChevronDownIcon,
  LightBulbIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import { handleError } from "../../../../errors/errorUtils";

const DESCRIPTION_STEP = "description";
const OUTLINE_STEP = "outline";

export default function Wizard({
  survey,
  setSurvey,
  onGenerateSurvey,
  generatingSurvey = false,
}: {
  survey: SurveyData;
  setSurvey: Dispatch<SetStateAction<SurveyData>>;
  onGenerateSurvey: () => void;
  generatingSurvey: boolean;
}) {
  const steps = [DESCRIPTION_STEP, OUTLINE_STEP];
  const {
    setObjectives,
    questionCount,
    stepCount,
    setRespondentType,
    setAudience,
  } = useSurveySetters(survey, setSurvey);

  const [descriptionOpen, setDescriptionOpen] = useState(
    survey.plan.length === 0
  );
  const [outlineOpen, setOutlineOpen] = useState(survey.plan.length > 0);
  const [generating, setGenerating] = useState(false);

  function handleTitleGeneration() {
    if (survey.title) return;
    let newTitle = "";
    createTitleCompletion(survey.objectives, (text) => {
      newTitle += text || "";
      setSurvey((survey) => ({ ...survey, title: newTitle }));
    });
  }

  async function generatePlan(description: string, respondentProfile: string) {
    if (generating || !survey.respondentType) return;
    try {
      if (stepCount > 0) {
        const confirm = window.confirm(
          "Are you sure you want to regenerate your plan? This will overwrite any changes you've made."
        );
        if (!confirm) return;
      }
      let planText = "";
      setGenerating(true);
      await createPlanCompletion(
        {
          description: fromHTML(description),
          respondentProfile: fromHTML(respondentProfile),
          surveyId: survey.id,
          respondentType: survey.respondentType,
        },
        (text, done) => {
          planText += text || "";
          if (done) {
            setGenerating(false);
            console.log("Plan text: ", planText);
          }
          setSurvey((survey: SurveyData) => {
            return {
              ...survey,
              plan: toHTML(planText),
            };
          });
        }
      );
    } catch (err) {
      handleError(err);
      setGenerating(false);
    }
  }

  const currentStep = calculateCurrentStep();

  function calculateCurrentStep() {
    if (survey.sections.length > 1) return 2;
    if (survey.plan.length > 0) return 1;
    return 0;
  }

  return (
    <div className="pb-24">
      <WizardSection
        icon={<LightBulbIcon className="w-4" />}
        title="Description"
        open={descriptionOpen}
        setOpen={setDescriptionOpen}
        step={0}
        currentStep={currentStep}
        totalSteps={steps.length}
      >
        <WizardDescriptionForm
          survey={survey}
          setObjectives={setObjectives}
          setRespondentType={setRespondentType}
          setAudience={setAudience}
          onSubmit={async (generate: boolean) => {
            handleTitleGeneration();
            setOutlineOpen(true);
            setDescriptionOpen(false);
            if (generate) {
              generatePlan(survey.objectives, survey.audience);
            }
          }}
          showNext={true}
        />
      </WizardSection>
      <WizardSection
        icon={<ListBulletIcon className="w-5" />}
        title="Outline"
        open={outlineOpen}
        setOpen={setOutlineOpen}
        step={1}
        currentStep={currentStep}
        totalSteps={steps.length}
        disabled={survey.objectives.length === 0 || !survey.respondentType}
      >
        <WizardPlanForm
          survey={survey}
          setSurvey={setSurvey}
          generating={generating}
          showNext={!generating && !generatingSurvey && questionCount <= 1}
          onSubmit={() => onGenerateSurvey()}
        />
      </WizardSection>
    </div>
  );
}

function WizardSection({
  title,
  open,
  setOpen,
  children,
  step,
  currentStep,
  totalSteps,
  icon,
  disabled = false,
}: {
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  children: React.ReactNode | React.ReactNode[];
  step: number;
  currentStep: number;
  totalSteps: number;
  icon: React.ReactNode;
  disabled?: boolean;
}) {
  const priorStepActive = currentStep > step - 1;
  const isActive = currentStep >= step;
  const nextStepActive = currentStep > step;
  const isFirstStep = step === 0;
  const isLastStep = totalSteps === step + 1;
  return (
    <div className="relative">
      {!isFirstStep && (
        <div
          className={`absolute h-full -z-5 top-0 ${
            priorStepActive ? "bg-sky-600" : "bg-gray-200"
          }`}
          style={{
            left: 23,
            height: 16,
            width: 2,
          }}
        ></div>
      )}
      {!isLastStep && (
        <div
          className={`absolute h-full -z-5 ${
            nextStepActive
              ? "bg-sky-600"
              : priorStepActive
              ? "bg-gradient-to-b from-sky-600 to-gray-200"
              : "bg-gray-200"
          }`}
          style={{
            left: 23,
            top: 32,
            height: `calc(100% - 32px)`,
            width: 2,
          }}
        ></div>
      )}
      <div className="flex flex-row">
        <div className="p-2 h-16 flex flex-row items-center">
          <div
            className={`rounded-full ${
              isActive ? "bg-sky-600 text-white" : "bg-gray-200 text-gray-400"
            }  w-8 h-8 flex flex-row justify-center items-center z-5 relative`}
          >
            {icon}
          </div>
        </div>
        <div className={`${isLastStep ? "" : "border-b"} flex-1`}>
          <div className="flex flex-row items-center h-16">
            <div className="flex-1 px-2 font-medium">{title}</div>
            <div className="px-4 space-x-2">
              {disabled ? null : (
                <button
                  className="hover:bg-gray-100 rounded p-1.5"
                  onClick={() => setOpen(!open)}
                >
                  <motion.div
                    animate={open ? "open" : "closed"}
                    variants={{
                      open: { rotate: 0 },
                      closed: { rotate: -90 },
                    }}
                    initial={false}
                  >
                    <ChevronDownIcon className="w-4 h-4" />
                  </motion.div>
                </button>
              )}
            </div>
          </div>
          <div className={`${open && !disabled ? "" : "hidden"} px-2`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
