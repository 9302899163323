export const exampleDescriptions: {
  description: string;
  respondentType: string;
  audience: string;
}[] = [
  {
    respondentType: "b2b",
    description: `This survey aims to gauge project management SaaS vendor awareness, usage, and decision-making involvement among company professionals. It also explores satisfaction, spending trends, and multi-sourcing patterns to understand competitive positioning and projected changes in the project management SaaS market.`,

    audience: `Ideal respondents are full-time professionals at mid-to-large companies involved in selecting or managing project management SaaS vendors, especially those in procurement or product management roles. They should be familiar with major project management SaaS vendors, have direct influence over spending, and insights into adoption or consolidation plans.`,
  },
  {
    respondentType: "b2c",
    description: `Survey topic: Spices in supermarkets e.g. garlic, cinnamon, chili powder, salt, pepper, paprika.`,
    audience: `Ideal respondents are primary household shoppers who are the primary decision-makers for grocery shopping. They should be familiar with the spices category and have purchased spices in the past 6 months.`,
  },
  {
    description: `I want to understand the market for braking systems, where typical customers are vehicle manufacturers like Ford, Nissan, Toyota. Competitors in the space are Brembo and Zimmermann. I particularly want to focus on brand perceptions and purchasing considerations.`,
    respondentType: "b2b",
    audience: `Ideal respondents are professionals in the automotive industry who are involved in the selection of braking systems for vehicles. They should have knowledge of the competitive landscape and be familiar with the brands mentioned.`,
  },
  {
    respondentType: "b2c",
    description: `Evaluate the growth potential of Melissa & Doug, an early childhood toy brand. Understand parents' purchasing habits and criteria for their infant, toddler, and preschool children's toys, including their purchase criteria, the types of toys they favor, and which channels they shop at. Measure customer advocacy for various competitive brands.`,
    audience: `Ideal respondents are parents of children aged 0-5 who have purchased toys in the past 6 months. They should be familiar with the Melissa & Doug brand and have purchased toys from a variety of brands.`,
  },
];
